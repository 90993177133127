import Vue from "vue";
import Router from "vue-router";
import { pinia } from "@/main";
import { useThemeConfigStore } from "@/stores/theme/themeConfig";
import { useUserStore } from "@/stores/user/user";

// we change the push prototype function to not throw an error when app is already on the page in case of error repeating
const { push } = Router.prototype;

const { isNavigationFailure, NavigationFailureType } = Router;

Router.prototype.push = function (location) {
  return push.call(this, location).catch((error) => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
      throw Error(error);
    }
  });
};

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: () => import("@/views/app/Index"),
    redirect: "/welcome",
    children: [
      {
        path: "/storage",
        redirect: "/storage/databases",
        component: () => import("@/views/app/storage/Storage"),
        meta: {
          subscription: "cloud-database",
        },
        children: [
          {
            path: "databases",
            name: "databases",
            meta: {
              breadcrumb: [{ name: "Storage" }, { name: "Cloud Databases" }],
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/storage/cloud-databases/DatabaseList"),
          },
          {
            path: "new-database",
            name: "new-database",
            meta: {
              breadcrumb: [
                { name: "Storage" },
                { name: "Cloud Databases" },
                { name: "New Cloud Database" },
                { name: "Finalise Order" },
              ],
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/storage/cloud-databases/NewDatabase"),
          },
          {
            path: "database-overview/:id",
            name: "database-overview",
            meta: {
              breadcrumb: [{ name: "Storage" }, { name: "Cloud Databases" }],
              hasToFetchData: true,
            },
            props: true,
            component: () =>
              import("@/views/app/storage/cloud-databases/DatabaseOverview"),
          },
        ],
      },
      {
        path: "/compute/serverless-containers",
        component: () => import("@/views/app/compute/Compute"),
        redirect: "/compute/serverless-containers/namespaces",
        name: "serverless-container",
        meta: {
          subscription: "serverless-container",
        },
        children: [
          {
            path: "namespaces",
            name: "namespaces",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/compute/serverless-containers/NamespaceList"),
          },
          {
            path: "namespace-overview/:namespaceId",
            name: "namespace-overview",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import(
                "@/views/app/compute/serverless-containers/NamespaceOverview.vue"
              ),
          },
          {
            path: "new-namespace",
            name: "new-namespace",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/compute/serverless-containers/NewNamespace"),
          },
          {
            path: ":id/new-container",
            name: "new-container",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/compute/serverless-containers/NewContainer"),
          },
          {
            path: ":namespaceId/new-registry",
            name: "new-registry",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/compute/serverless-containers/NewRegistry"),
          },
          {
            path: ":namespaceId/new-volume",
            name: "new-volume",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import("@/views/app/compute/serverless-containers/NewVolume"),
          },
          {
            path: ":namespaceName/:volumeId/increase-size",
            name: "volume-increase",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import(
                "@/views/app/compute/serverless-containers/IncreaseVolume"
              ),
          },
          {
            path: "namespace/:namespaceId/container/:id",
            name: "container-overview",
            meta: {
              hasToFetchData: true,
            },
            component: () =>
              import(
                "@/views/app/compute/serverless-containers/ContainerOverview.vue"
              ),
          },
        ],
      },
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/app/welcome/WelcomePage.vue"),
      },
      {
        path: "/not-found",
        name: "not-found",
        component: () => import("@/views/app/error/NotFound.vue"),
      },
      {
        path: "/error-occurred",
        name: "error-occurred",
        component: () => import("@/views/app/error/Error.vue"),
        beforeEnter: (to, from, next) => {
          if (from.path === "/") {
            next({ name: "welcome" });
          }
        },
      },
    ],
  },
  {
    path: "*",
    name: "no-route",
    redirect: "/not-found",
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const themeConfigStore = useThemeConfigStore(pinia);
  const userStore = useUserStore(pinia);
  // If this isn't an initial page load.
  // make sure you make the NotFound false and hide the not found component
  themeConfigStore.setNotFoundState(false);
  // Hide the restricted warning banner
  themeConfigStore.showRestrictedWarningBanner(false);
  if (await Vue.prototype.$featureFlag.isEnabled("billing")) {
    if (to.matched.length !== 0) {
      await userStore.setUserPermissions();
    }
  }

  if (to.meta.hasToFetchData) {
    // Show the skeleton loader
    themeConfigStore.toggleThemeFetchingState(true);
  }

  next();
});

export default router;
